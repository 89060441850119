import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import Spinner from '@atlaskit/spinner';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { Button } from '@/components';
import Field from '@/components/forms/Field';
import { useStore } from '@/hooks';
import UserService from '@/lib/UserService';

import CrossIcon from '@/images/icons/cross.svg';

import styles from './styles.module.scss';

const ReferModal = ({}) => {
  const { referText, referralOpen, setReferralOpen } = useStore('globals');
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>('');

  const handleSubmit = async (values: ReferralForm, { setSubmitting }: FormikHelpers<ReferralForm>) => {
    setSubmitting(true);
    const res = await UserService.referFriend(values);

    if (!res?.success) {
      setErrorMsg(res?.error ?? 'An error occured');
      setSubmitting(false);
      return;
    }

    setErrorMsg(null);
    setSubmitted(true);
    setSubmitting(false);
  };

  useEffect(() => {
    if (!referralOpen) {
      submitted && setSubmitted(false);
      errorMsg && setErrorMsg(null);
    }
  }, [errorMsg, referralOpen, submitted]);

  return (
    <Modal isOpen={referralOpen} ariaHideApp={false} onRequestClose={() => setReferralOpen(false)} shouldCloseOnEsc>
      <div className={styles.refer}>
        <button className={styles.close} onClick={() => setReferralOpen(false)}>
          <CrossIcon />
        </button>
        <Formik
          onSubmit={handleSubmit}
          initialValues={ReferModal.initialValues}
          validationSchema={ReferModal.validationSchema}
        >
          {({ values, isSubmitting, errors }) => (
            <>
              {submitted ? (
                <>
                  <h2 className={styles.heading}>Success!</h2>
                  <p>Your referral has been sent to {values.email}</p>
                </>
              ) : (
                <Form>
                  <h2 className={styles.heading}>Refer a friend</h2>
                  <div dangerouslySetInnerHTML={{ __html: referText }} />
                  {errorMsg && <p className={styles.error}>{errorMsg}</p>}
                  <Field
                    small
                    name="fullName"
                    type="text"
                    placeholder="Your friend's name"
                    required
                    formik
                    errors={errors}
                    disabled={isSubmitting}
                  />
                  <Field
                    small
                    name="email"
                    type="email"
                    placeholder="Your friend's email"
                    required
                    formik
                    errors={errors}
                    disabled={isSubmitting}
                  />
                  <Field
                    small
                    name="dealership"
                    type="text"
                    placeholder="Preferred dealership?"
                    formik
                    errors={errors}
                    disabled={isSubmitting}
                  />
                  {isSubmitting ? (
                    <div className={styles.loading}>
                      <Spinner size="large" />
                    </div>
                  ) : (
                    <Button type="submit">Submit</Button>
                  )}
                </Form>
              )}
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

ReferModal.initialValues = {
  fullName: '',
  email: '',
  dealership: '',
} as ReferralForm;

ReferModal.validationSchema = object({
  fullName: string().required("Your friend's name is required"),
  email: string().email().required("Your friend's email is required"),
  dealership: string(),
});

export default observer(ReferModal);
