import { computed, flow, makeAutoObservable } from 'mobx';
import { isHydrated, makePersistable, stopPersisting } from 'mobx-persist-store';
import { captureMessage } from '@sentry/nextjs';
import { Client } from 'urql';

import BlackhawkService from '@/lib/BlackhawkService';
import CommerceService from '@/lib/CommerceService';
import { extractItems } from '@/lib/GraphQLHelper';
import { SendRequestResponse } from '@/lib/SendRequest';
import { localStorage, localStorageSupported } from '@/lib/Storage';

import query from './query.graphql';

export interface BlackhawkGiftCard extends GiftCardFragment {
  bh?: BlackhawkProduct;
}

class Cart {
  commerceCart?: Commerce.Cart;
  cartNumber = 0;
  hydrated = false;
  cmsProducts: BlackhawkGiftCard[] = [];
  activeBrowseProductType = 'eTickets';

  currentSchemaVersion = '1';

  constructor(props = {}) {
    Object.assign(this, props);

    makeAutoObservable(this, {
      productQtyMap: computed,
      lineItemIds: computed,
      lineItems: computed,
      couponCode: computed,
      totalRRP: computed,
      availableBlackHawkIds: computed,
    });

    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: `cartStore-v${this.currentSchemaVersion}`,
        properties: ['activeBrowseProductType'],
        storage: localStorageSupported ? localStorage : undefined,
      });
    }
  }

  init = flow(function* (this: Cart, urqlClient: Client, cookie?: string) {
    const cookieSSR = cookie || '';
    const response: Commerce.ControllerActions.Cart.GetCart.Response | null = yield CommerceService.getCartSSR(
      cookieSSR,
    );
    if (response?.cart) {
      this.setCart(response.cart);
      this.setCartNumber(this.commerceCart?.lineItems?.length || 0);
    }

    const { data } = yield urqlClient.query<GiftCardQuery, GiftCardQueryVariables>(query, {}).toPromise();
    // Get list of giftCards with blackhawk Ids set
    const giftCards = extractItems<GiftCardFragment>(data.products)?.filter((f) => !!f.blackhawkId);
    if (giftCards) {
      const blackhawkProducts = yield BlackhawkService.getAvailableCards(giftCards.map((p) => p.blackhawkId!));
      this.parseProducts(giftCards, blackhawkProducts);
    }
  });

  stopPersisting = () => {
    stopPersisting(this);
  };

  get isHydrated() {
    return isHydrated(this);
  }

  parseProducts = async (products: GiftCardFragment[], bhProducts: BlackhawkProduct[]) => {
    const blackhawkIds = bhProducts && bhProducts?.map((bhp: BlackhawkProduct) => bhp.defaultProductConfiguration);
    // only show products that can be read by the blackhawk api
    if (!!blackhawkIds?.length) {
      const available = products
        .filter((p) => blackhawkIds.includes(p.blackhawkId!))
        .map((p) => ({
          bh: bhProducts?.find((bhp) => bhp.defaultProductConfiguration === p.blackhawkId),
          ...p,
        }));

      this.cmsProducts = available;
    }
  };

  getProduct = (slug: string) => {
    return this.cmsProducts.find((cp) => cp.slug === slug);
  };

  sendPaymentDetails = flow(function* (this: Cart, paymentId: string) {
    const response: SendRequestResponse<Commerce.ControllerActions.Payments.Pay.Response> =
      yield CommerceService.sendPayment(paymentId);
    if (!response.success && response?.data?.errors) {
      captureMessage(response.data.errors[0], { extra: response.data });
    }
    return response;
  });

  getOrder = flow(function* (this: Cart, orderId: string) {
    const response: SendRequestResponse<Commerce.ControllerActions.Cart.GetCart.Response> =
      yield CommerceService.getOrder(orderId);
    if (!response.success && response?.data?.errors) {
      captureMessage(response.data.errors[0], { extra: response.data });
    }
    return response;
  });

  addCouponCode = async (code?: string) => {
    return await CommerceService.addCouponCode(code);
  };

  updateItemQty = async (item: Commerce.LineItem, qty: number) => {
    const response = await CommerceService.updateItem(item.id.toString(), qty);
    if (!response?.data?.cart) return;

    this.setCart(response.data.cart);
    this.setCartNumber(response.data.cart.lineItems.length || 0);
  };

  getCommerceCart = flow(function* (this: Cart) {
    const response: Commerce.ControllerActions.Cart.GetCart.Response | null = yield CommerceService.getCart();
    if (response?.cart) {
      this.setCart(response.cart);
      this.setCartNumber(this.commerceCart?.lineItems?.length || 0);
    }
  });

  deleteItemFromCart = async (item: Commerce.LineItem) => {
    const response = await CommerceService.removeItem(item.id.toString());
    if (!response?.data) return;

    this.setCart(response.data.cart);
    this.setCartNumber(response.data.cart.lineItems.length || 0);
  };

  addToCraftCommerce = async (item: ETicketFragment | GiftCardFragment, qty: number, variant = 0) => {
    const order: Cart.OrderItem = { item, qty };
    const response = await CommerceService.addToCart([order], variant);
    if (!response?.data?.cart) return;

    this.setCart(response.data.cart);
    this.setCartNumber(response.data.cart.lineItems.length || 0);
  };

  get productQtyMap() {
    const productQtyMap: Record<string, Commerce.LineItem> = {};
    this.commerceCart?.lineItems?.forEach((lineItem: Commerce.LineItem) => {
      if (!!lineItem?.snapshot?.productId) {
        productQtyMap[lineItem.snapshot.productId] = lineItem;
      }
    });
    return productQtyMap;
  }

  get lineItemIds() {
    if (this.commerceCart?.lineItems?.length) {
      return this.commerceCart?.lineItems?.map((li) => li?.snapshot?.productId ?? '');
    }

    return [];
  }

  get lineItems() {
    if (this.commerceCart?.lineItems?.length) {
      return this.commerceCart?.lineItems;
    }

    return [];
  }

  setCartNumber = (qty: number) => {
    this.cartNumber = qty;
  };

  setCart = (cart: Commerce.Cart) => {
    this.commerceCart = cart;
  };

  get couponCode() {
    return this.commerceCart?.couponCode;
  }

  get totalRRP() {
    return this.lineItems?.reduce((total, curr) => (total += parseInt(curr.options.rrp, 10) * curr.qty), 0) || 0;
  }
  isItemInCart = (item: ETicketFragment | GiftCardFragment) => {
    return !!this.productQtyMap[item.id!];
  };

  setActiveProduct = (type: string) => {
    this.activeBrowseProductType = type;
  };

  get availableBlackHawkIds() {
    return this.cmsProducts.map((p: BlackhawkGiftCard) => p.blackhawkId);
  }
}

export default Cart;
