import { captureException } from '@sentry/nextjs';

import { getUserStore } from '@/stores';

import Csrf from './Csrf';
import SendRequest from './SendRequest';
import { routeToPage } from './Utils';

interface SuccessResponse {
  success: true;
}

interface ErrorResponse {
  success: false;
  error: string;
}

export default class UserService {
  public static loginUser(loginParams: Craft.ControllerActions.Users.Login.Params) {
    return this.doRequest<typeof loginParams, Craft.ControllerActions.Users.Login.Response>(
      '/api/users/login',
      loginParams,
    );
  }

  public static async loggedInSSR(clientCookies?: string): Promise<Nullable<Craft.SessionInfo>> {
    if (clientCookies) {
      return await this.doRequestSSR<null, Craft.SessionInfo>('/api/users/session-info', clientCookies);
    }
    // if no clientCookies, the user is always a guest
    return null;
  }

  public static async logoutUser(redirect = '/') {
    await UserService.doRequest('/api/users/logout');
    routeToPage(redirect);
  }

  public static async resetPassword(email: string) {
    return await UserService.doRequest(`/api/sendResetPasswordEmail`, { email });
  }

  public static async sendActivationEmail(email: string) {
    return await UserService.doRequest(`/api/sendActivationEmail`, { email });
  }

  public static async registerUser(email: string) {
    return await UserService.doRequest<null, { inProgress: boolean }>('/api/registerUser', { email });
  }

  public static async registerManualUser(userData: any) {
    return await UserService.doRequest<null, SuccessResponse | ErrorResponse>('/api/create/user', userData);
  }

  public static async referFriend(data: ReferralForm) {
    return await UserService.doRequest<null, SuccessResponse | ErrorResponse>(`/api/referUser`, data);
  }

  public static async registerKeyring(keyring: string) {
    return await UserService.doRequest<null, { success: boolean; error: string | null }>(`/api/registerKeyring`, {
      keyring,
    });
  }

  public static async getKeyring() {
    return await UserService.doRequest<null, { success: boolean; error: string | null; keyring: number | null }>(
      `/api/getKeyring`,
    );
  }

  public static socialLogin(provider: string, redirect = '/') {
    window?.location?.assign(`/api/users/login/social?provider=${provider}&redirect=${redirect}`);
  }

  private static async doRequest<Params, Return>(path: string, data?: any) {
    const formBody = new FormData();
    const userStore = getUserStore();
    const csrf = userStore?.csrfToken ?? (await Csrf.getCsrf());
    formBody.append('CRAFT_CSRF_TOKEN', csrf);

    if (data) {
      Object.keys(data).forEach((key) => formBody.append(key, data[key] == null ? '' : data[key]));
    }

    const headers = new Headers(
      process.env.NODE_ENV === 'development'
        ? {
            Accept: 'application/json',
            'X-CSRF-TOKEN': csrf,
            'X-Debug': 'enable',
          }
        : {
            Accept: 'application/json',
            'X-CSRF-TOKEN': csrf,
          },
    );

    try {
      const res = await SendRequest<Params, Return>({
        path,
        method: 'POST',
        headers,
        formBody,
      });
      return res.data;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public static async doRequestSSR<Params, Return>(path: string, cookies: string) {
    try {
      const res = await SendRequest<Params, Return>({
        path,
        headers: {
          cookie: cookies,
        },
      });
      return res.data ?? null;
    } catch (error) {
      captureException(error);
      return null;
    }
  }
}
