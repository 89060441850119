import React, { useCallback } from 'react';

import { extractItems } from '@/lib/GraphQLHelper';
import { parseLinkField } from '@/lib/Utils';

import styles from './styles.module.scss';

interface Props {
  section: FooterLinkFragment;
}

const LinkSection = ({ section }: Props) => {
  const getHref = useCallback(
    (linkField?: WebLinkFragment | EntryLinkFragment) => linkField && parseLinkField(linkField),
    [],
  );

  return (
    <div className={styles.linkSection}>
      {section && (
        <a href={getHref(section.linkField?.[0] as WebLinkFragment | EntryLinkFragment)} className={styles.parentLink}>
          {section.title}
        </a>
      )}
      {extractItems<FooterLinkChildrenFragment>(section?.children)
        ?.filter((link) => link !== null && link !== undefined)
        .map((link) => (
          <a
            key={link?.slug}
            href={getHref(extractItems<WebLinkFragment | EntryLinkFragment>(link?.linkField)?.[0])}
            className={styles.childLink}
          >
            {link?.title}
          </a>
        ))}
    </div>
  );
};

export default LinkSection;
