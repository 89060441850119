import React, { useRef, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Button } from '@/components';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { extractItems } from '@/lib/GraphQLHelper';

import ChevronIcon from '@/images/icons/chevron.svg';

import styles from './styles.module.scss';

interface Props {
  headerLink: HeaderLinkFragment;
}

const HeaderLinkMenu = ({ headerLink }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const background = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useOnClickOutside(background, () => setOpenModal(false));

  const getLinkFromEntry = (headerLink: HeaderLinkFragment | HeaderLinkChildrenFragment) =>
    headerLink?.linkField?.[0]?.__typename === 'linkField_weblink_BlockType'
      ? headerLink?.linkField?.[0]?.weblink
      : headerLink?.linkField?.[0]?.Single?.[0]?.uri;

  const parentLink = getLinkFromEntry(headerLink);
  const dropdownLinks = extractItems<HeaderLinkChildrenFragment>(headerLink?.children);

  return !!dropdownLinks?.length ? (
    <div className={styles.headerLink}>
      <Button onClick={() => setOpenModal(!openModal)} design="ui" data-testid="modalButton">
        <span className={cc({ [styles.active]: parentLink && router.pathname.startsWith(parentLink) })}>
          {headerLink.title}
        </span>
        <div className={styles.chevron} data-modalopen={openModal}>
          <ChevronIcon />
        </div>
      </Button>
      {openModal && (
        <>
          <div className={styles.background} data-modalopen={openModal} ref={background} />
          <div className={styles.dropdownModal} data-testid="modal" data-modalopen={openModal}>
            <div className={styles.links}>
              {dropdownLinks?.map((dropdownLink) => {
                const href = getLinkFromEntry(dropdownLink);
                return (
                  href && (
                    <a href={href} key={dropdownLink.slug}>
                      {dropdownLink.title}
                    </a>
                  )
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <a
      href={parentLink || ''}
      className={cc({ [styles.active]: parentLink === router.pathname })}
      data-test-id="simple-headerlink"
    >
      {headerLink.title}
    </a>
  );
};

export default observer(HeaderLinkMenu);
