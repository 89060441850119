import { flow, makeAutoObservable } from 'mobx';
import { captureMessage, setExtras } from '@sentry/nextjs';
import { AnyVariables, Client, OperationResult } from 'urql';

import { extractFragment, extractItems } from '@/lib/GraphQLHelper';
import NowUTCString from '@/lib/nowUTCString';

import query from './query.graphql';

export default class Globals {
  now = NowUTCString();
  site = 'mymotorewards';
  siteName = 'mymoto rewards';
  logo = '';
  cardBackground = '';
  phone = '';
  email = '';
  address = '';
  postalAddress = '';
  description = '';
  favicon?: Favicon;
  referText =
    '<p>Refer a friend to a mymoto dealership and when they purchase a car you will receive a $150 gift voucher.</p>';
  prettySiteName = 'mymoto rewards';
  isAutomotive = false;
  servicingUrl = '';
  referralOpen = false;
  branchCodeToDealershipMap: Maybe<Pick<BranchCodeToDealershipMapTableRow, 'branchCode' | 'dealershipSlug'>>[] = [];
  theme: Nullable<Theme.ThemeData> = null;
  myCarEnabled = false;

  constructor(props = {}) {
    Object.assign(this, props);

    makeAutoObservable(this);
  }

  init = flow(function* (this: Globals, urqlClient: Client, site: string) {
    this.site = site;

    let results!: OperationResult<GlobalsQuery, AnyVariables>;
    try {
      results = yield urqlClient.query<GlobalsQuery>(query, {}).toPromise();
    } catch (error) {
      setExtras({ error });
      captureMessage(`Globals query failed`, 'warning');
    }

    const globalSet = extractFragment<GlobalsFragment>(results?.data?.globalSet);
    const customisation = extractFragment<CustomisationGlobalSet>(results?.data?.customisation ?? null);
    const systemGlobalSet = extractFragment<SystemGlobalSet>(results?.data?.system ?? null);

    if (globalSet) {
      this.phone = globalSet?.phone || this.phone;
      this.email = globalSet?.email || this.email;
      this.address = globalSet?.address?.address || this.address;
      this.postalAddress = globalSet?.postalAddress || this.postalAddress;
      this.description = globalSet?.description || this.description;
      this.logo = globalSet?.logo?.[0]?.url || '' || this.logo;
      this.cardBackground = globalSet?.membershipCardBackground?.[0]?.url || this.cardBackground;
      this.prettySiteName = globalSet?.prettySiteName || this.prettySiteName;
      this.isAutomotive = globalSet?.isAutomotive || this.isAutomotive;
      this.servicingUrl = globalSet?.servicingUrl || this.servicingUrl;
      this.branchCodeToDealershipMap = globalSet?.branchCodeToDealershipMap || this.branchCodeToDealershipMap;
      this.myCarEnabled = globalSet?.myCarEnabled || this.myCarEnabled;
      this.referText = globalSet?.body || this.referText;
    } else {
      setExtras({ results });
      captureMessage('globals was empty', 'warning');
    }

    if (customisation) {
      const favicon = extractItems<FaviconNeoField>(customisation?.favicon);
      this.favicon = favicon ?? undefined;
    }

    this.theme = {
      customisation,
      system: systemGlobalSet,
    };
  });

  setReferralOpen = (isOpen: boolean) => {
    this.referralOpen = isOpen;
  };
}
