import React from 'react';
import cc from 'classcat';
import Head from 'next/head';
import { useRouter } from 'next/router';

import useIsMobile from '@/hooks/useIsMobile';
import useSEO from '@/hooks/useSEO';
import useStore from '@/hooks/useStore';
import { withUrql } from '@/lib/urqlClient';
import PublicEnvironment from '@/stores/PublicEnvironment';

import BottomBar from './components/BottomBar';
import MenuBar from './components/MenuBar';
import UserMenu from './components/UserMenu';

import styles from './styles.module.scss';

const Header = () => {
  const { logo, siteName } = useStore('globals');
  const { loggedIn } = useStore('userStore');
  const [isMobile, isLtDesktop] = useIsMobile(768, 1025);
  const router = useRouter();
  const uri = router.asPath.replace(/\?.*$/, '');
  const [seoHead, seoScripts] = useSEO(uri);
  const path = router.pathname.split('/')[1];

  const disabledPaths = [
    'login',
    'cart',
    'checkout',
    'set-password',
    'update',
    'activate',
    'confirmation',
    'forgot-password',
  ];

  if (isMobile && disabledPaths.includes(path)) {
    return null;
  }

  return (
    <>
      {seoScripts}
      <Head>
        {seoHead}
        <link key="canonical" rel="canonical" href={uri} />
      </Head>
      <div className={styles.header} data-testid="header" data-e2e={PublicEnvironment.get('MOCK_GRAPHQL_SERVER')}>
        {isMobile ? (
          <div className={styles.mobile} data-testid="mobile">
            <div className={styles.top}>
              {logo ? (
                <a href="/">
                  <img className={styles.logo} src={logo} alt={siteName} />
                </a>
              ) : (
                <h3>
                  <a href="/">{siteName}</a>
                </h3>
              )}
              <UserMenu />
            </div>
          </div>
        ) : (
          <div className={styles.desktop}>
            <div className={styles.topLinks}>
              {loggedIn && (
                <a
                  href="/competitions"
                  className={cc({ [styles.active]: path === 'competitions' })}
                  data-testid="active"
                >
                  Competitions
                </a>
              )}
              <a href="/help-centre" className={cc({ [styles.active]: path === 'help-centre' })}>
                Help
              </a>
            </div>
            <div className={styles.menuBar}>
              {logo ? (
                <a href="/" className={styles.logo}>
                  <img className={styles.logoImage} src={logo} alt={siteName} />
                </a>
              ) : (
                <h3>
                  <a href="/">{siteName}</a>
                </h3>
              )}
              <div>
                <MenuBar />
                <UserMenu />
              </div>
            </div>
          </div>
        )}
        {isLtDesktop && <BottomBar />}
      </div>
    </>
  );
};

export default withUrql(Header);
