import Log from '@/lib/Log';

import SendRequest from './SendRequest';

export default class CSRF {
  static getSessionInfo = async () => {
    try {
      const session = await SendRequest<null, Craft.SessionInfo>({
        path: '/api/users/session-info',
      });
      if (session.success) {
        return session.data;
      }
      return null;
    } catch (error) {
      Log.exception(error, {});
      return null;
    }
  };

  static getCsrf = async () => {
    const session = await this.getSessionInfo();

    if (session) {
      return session.csrfTokenValue;
    }
    return '';
  };
}
