var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"TQFeWH-mcSg0ANAyyzVik"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

import PublicEnvironment from './stores/PublicEnvironment';

if (PublicEnvironment.get('SENTRY_ENABLED')) {
  Sentry.init({
    environment: PublicEnvironment.get('ENV'),
    dsn: PublicEnvironment.get('SENTRY_DSN'),
    release: PublicEnvironment.get('APP_VERSION'),
    tracesSampleRate: 0.25,
    ignoreErrors: [
      /Blocked a frame with origin/,
      /LCM/,
      /Script error/,
      /Failed to register a ServiceWorker/,
      /sw.js/,
      /NetworkError/,
      'document.getElementsByClassName.ToString is not a function',
      /Script load failed/,
      /International roaming is currently off/,
      /NotAllowedError/,
      '<unknown>',
      'undefined',
    ],
    denyUrls: [
      /gtm-msr\.appspot\.com/,
      /a\.adroll\.com/,
      /trkcall\.com/,
      /script\.hotjar\.com/,
      /formobile\.com\.au/,
      /script\.hotjar\.com/,
      /maps\.googleapis\.com/,
      /google-analytics\.com/,
    ],
    initialScope: {
      tags: { client: true },
    },
    integrations: [new ExtraErrorData()],
  });

  process.on('unhandledRejection', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });

  process.on('uncaughtException', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });
}
