import getOrCreateStore from '@/lib/getOrCreateStore';
import Cart from '@/stores/Cart';
import Globals from '@/stores/Globals';
import PublicEnvironment from '@/stores/PublicEnvironment';
import UserStore from '@/stores/UserStore';

const getCart = getOrCreateStore('cart', Cart);
const getGlobals = getOrCreateStore('globals', Globals);
const getUserStore = getOrCreateStore('userStore', UserStore);
const getPublicEnvironment = PublicEnvironment.getInstance;

export { getCart, getGlobals, getUserStore, getPublicEnvironment };
