import { captureException } from '@sentry/nextjs';

import { getUserStore } from '@/stores';

import Csrf from './Csrf';
import { extractFragment } from './GraphQLHelper';
import SendRequest from './SendRequest';

export default class CommerceService {
  /**
   * Orders to be added to the cart
   * @param orders to be added to the cart
   * @return the newly changed cart
   */
  static addToCart = async (orders: Cart.OrderItem[], variant = 0) => {
    const formData = new FormData();
    // loop over all products and make items[index]
    orders.forEach((p: Cart.OrderItem, i: number) => {
      const productItemVariant = extractFragment<GiftCardVariantFragment | ETicketVariantFragment>(
        p?.item?.variants?.[variant],
      );
      const purchaseableId = productItemVariant?.id;
      if (purchaseableId) {
        formData.append(`purchasables[${i}][id]`, `${purchaseableId}`);

        const price =
          p.item.__typename === 'eTickets_Product'
            ? p.item.rrp
            : extractFragment<GiftCardVariantFragment>(p?.item?.variants?.[variant])?.fullValue;

        formData.append(`purchasables[${i}][options][rrp]`, `${price}`);
        formData.append(`purchasables[${i}][qty]`, `${p.qty}`);
      }
    });
    await CommerceService.doRequest('/api/cart/update-cart', formData);
    return await CommerceService.getCart();
  };

  /**
   * Update an items quantity in the cart
   * @param id of the line item to be edited
   * @param qty , the new quantity of the item
   */
  static updateItem = async (id: string, qty: number) => {
    const formData = new FormData();
    formData.append(`lineItems[${id}][qty]`, `${qty}`);
    await CommerceService.doRequest('/api/cart/update-cart', formData);
    return await CommerceService.getCart();
  };

  /**
   * Removes a single item from commerce cart
   * @param id of line item to be removed from cart
   * @return the newly changed cart
   */
  static removeItem = async (id: string) => {
    const formData = new FormData();
    formData.append(`lineItems[${id}][remove]`, 'true');
    await CommerceService.doRequest('/api/cart/update-cart', formData);
    return await CommerceService.getCart();
  };

  /**
   * Clears the cart entirely
   * @param lineItems , all line items to be removed
   * @return the newly changed cart
   */
  static clearCart = async (lineItems: Commerce.LineItem[]) => {
    const formData = new FormData();
    lineItems.forEach((li: any) => {
      formData.append(`lineItems[${li.id}][remove]`, 'true');
    });
    await CommerceService.doRequest('/api/cart/update-cart', formData);
    return await CommerceService.getCart();
  };

  /**
   * Get Cart from Craft Commerce
   * @return the Craft Commerce Cart
   */
  static getCart = async () => {
    return await CommerceService.doRequest<null, Commerce.ControllerActions.Cart.GetCart.Response>(
      '/api/cart/get-cart',
      new FormData(),
    );
  };

  /**
   * Gets Cart Server Side from Craft Commerce using cookies
   * @param cookie to get the cart with
   * @return the Craft Commerce Cart
   */
  static getCartSSR = async (cookie: string): Promise<Commerce.ControllerActions.Cart.GetCart.Response | null> => {
    return (
      (
        await CommerceService.doRequestSSR<null, Commerce.ControllerActions.Cart.GetCart.Response>(
          '/api/cart/get-cart',
          cookie,
        )
      )?.data ?? null
    );
  };

  /**
   * Get Order from Craft Commerce
   * @return the Craft Commerce Order
   */
  static getOrder = async (orderId?: string) => {
    return await CommerceService.doRequest<null, TicketsModule.OrderController.GetOrder.Response>(
      `/api/commerce/order?orderId=${orderId}`,
      new FormData(),
    );
  };

  /**
   * Get Order from Craft Commerce
   * @return Commerce.Order the Craft Commerce Order
   */
  static getOrderSSR = async (cookie?: string, orderId?: string) => {
    return await CommerceService.doRequestSSR<null, TicketsModule.OrderController.GetOrder.Response>(
      `/api/commerce/order?orderId=${orderId}`,
      `${cookie}`,
    );
  };

  /**
   * Get Orders from Craft Commerce
   * @return Commerce.Cart[] the Craft Commerce Order
   */
  static getOrdersSSR = async (cookie?: string) => {
    return await CommerceService.doRequestSSR<null, TicketsModule.OrderController.GetOrders.Response>(
      `/api/commerce/orders`,
      `${cookie}`,
    );
  };

  /**
   * add gateway and user details to cart
   */
  static storeUserDetails = async () => {
    const formData = new FormData();
    formData.append('gatewayId', '1');
    await CommerceService.doRequest('/api/cart/update-cart', formData);
  };

  /**
   */
  static registerPaymentMethodId = async (id: string) => {
    const formData = new FormData();
    await CommerceService.doRequest(`/api/register_payment_method?id=${id}`, formData);
  };

  /**
   * add coupon and to cart
   */
  static addCouponCode = async (code?: string) => {
    const formData = new FormData();
    formData.append('couponCode', code || '');
    return await CommerceService.doRequest<
      Commerce.ControllerActions.Cart.UpdateCart.Params,
      Commerce.ControllerActions.Cart.UpdateCart.Response
    >('/api/cart/update-cart', formData);
  };

  /**
   * get count of tickets purchased
   */
  static getPurchasedNo = async (ticketGroupId?: Maybe<string>) => {
    const formData = new FormData();
    if (ticketGroupId) {
      return (
        (await CommerceService.doRequest<null, number>(`/api/get_purchased?productOfferId=${ticketGroupId}`, formData))
          ?.data ?? 0
      );
    }
    return 0;
  };

  /**
   * Sends payment
   * @param paymentId
   */
  static sendPayment = async (paymentId: string) => {
    await CommerceService.storeUserDetails();
    const formData = new FormData();
    formData.append('paymentForm[stripe][paymentMethodId]', paymentId);

    return await CommerceService.doRequest('/api/cart/pay', formData);
  };

  static headers =
    process.env.NODE_ENV === 'development'
      ? {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          'X-Debug': 'enable',
        }
      : {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        };

  static async doRequest<T, R>(path: string, formData: FormData) {
    const userStore = getUserStore();
    const csrf = userStore?.csrfToken ?? (await Csrf.getCsrf());
    formData.append('CRAFT_CSRF_TOKEN', csrf);

    try {
      return await SendRequest<T, R>({
        path,
        method: 'POST',
        headers: this.headers,
        formBody: formData,
      });
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  static async doRequestSSR<T, R>(path: string, cookies: string) {
    try {
      return await SendRequest<T, R>({
        path,
        headers: {
          cookie: cookies,
        },
      });
    } catch (error) {
      captureException(error);
      return null;
    }
  }
}
