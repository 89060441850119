import React from 'react';
import cc from 'classcat';
import styles from './styles.module.scss';

export type ButtonType = 'submit' | 'reset' | 'button';
export type Styles =
  | 'default'
  | 'highlight'
  | 'highlight-borders'
  | 'highlight-transparent'
  | 'pricesavings'
  | 'ui'
  | 'landing'
  | 'social';
interface BaseProps {
  children?: React.ReactNode;
  type?: ButtonType;
  design?: Styles;
  size?: 'small' | 'medium' | 'large';
}

interface AnchorProps
  extends Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'type'> {
  href: string;
  onClick?: never;
  as?: never;
}

interface ButtonProps
  extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type'> {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: never;
  as?: string;
}

interface SubmitButtonProps
  extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type'> {
  as?: 'submit';
  onClick?: never;
  href?: never;
}

type Props = (AnchorProps | ButtonProps | SubmitButtonProps) & BaseProps;

const Button = ({ children, type = 'button', as, design = 'default', size, ...props }: Props) => {
  const Wrapper = props.href ? 'a' : 'button';

  return (
    <Wrapper
      type={as || props.href ? undefined : type}
      role="button"
      tabIndex={0}
      className={cc({ [styles.button]: true, [styles[design]]: true, [styles[size || '']]: size })}
      {...(props as any)}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
