import { captureMessage } from '@sentry/nextjs';

import PublicEnvironment from '@/stores/PublicEnvironment';

export default class BlackhawkService {
  public static async getAvailableCards(productIds: string[]) {
    const data = {
      productIds: productIds,
    };
    return await this.doRequest('/product-catalog', JSON.stringify(data));
  }

  static async doRequest(path: string, body?: any) {
    const server = typeof window === 'undefined' ? PublicEnvironment.get('BLACKHAWK_HOST') : '';
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: PublicEnvironment.get('TOKEN'),
    });
    try {
      const response = await fetch(`http://${server}${path}`, {
        method: body ? 'POST' : 'GET',
        headers,
        body,
        credentials: 'same-origin',
      }).then((r) => r.json());
      return response;
    } catch (error) {
      captureMessage(`unable to get gift cards, http://${server}${path} ${error}`);
      return undefined;
    }
  }
}
