import PublicEnvironment from '@/stores/PublicEnvironment';

/**
 * Determine the GraphQL URL
 *
 * - For a mocked GraphQL server, use localhost:4000 (@graphql-yoga/node)
 * - For development, use the stellate container (unless $STELLATE_DISABLED is "true")
 * - For other environments, use the remote stellate endpoint
 */
export default function getGraphQLEndpoint() {
  if (PublicEnvironment.get('MOCK_GRAPHQL_SERVER')) {
    return 'http://localhost:4000/graphql';
  }

  return typeof window !== 'undefined'
    ? `${window.location.origin}/graphql`
    : `http://${PublicEnvironment.get('NGINX_HOST') || 'localhost'}/graphql`;
}
