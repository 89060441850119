import { computed, flow, makeAutoObservable } from 'mobx';
import { GetServerSidePropsContext } from 'next';

import PublicEnvironment from '@/stores/PublicEnvironment';

class UserStore {
  // csrf token is valid as long as the session
  session: Nullable<Craft.SessionInfo> = null;
  csrfToken: Nullable<string> = null;
  initialised = false;

  neatIdeasToken?: string;

  constructor(props: Partial<UserStore> = {}) {
    Object.assign(this, props);

    makeAutoObservable(this, {
      loggedIn: computed,
      uuid: computed,
    });
  }

  init = flow(function* (this: UserStore, session?: Nullable<Craft.SessionInfo>) {
    // Session can be initialised from ssr within `getServerSideProps()`. If so no need to get session again.
    if (session) {
      this.session = session;
      this.csrfToken = session.csrfTokenValue;
    }
  });

  get loggedIn() {
    return !!this.session && !this.session.isGuest;
  }

  /**
   * Returns the correct GraphQL argument value for filtering by `exclusive`.
   * Will be `null` for logged in users (don't filter by exclusive) and `false` for guests (only non-exclusive)
   */
  get exclusive() {
    return this.loggedIn ? null : false;
  }

  get uuid() {
    return !this.session?.isGuest ? this.session?.uid : null;
  }

  get id() {
    return !this.session?.isGuest ? this.session?.id : null;
  }

  async ensureNeatIdeasToken(
    ctx: GetServerSidePropsContext,
    email?: Nullable<string>,
    firstName?: Nullable<string>,
    lastName?: Nullable<string>,
  ) {
    const { res } = ctx;

    // Server-side only, email required
    if (typeof window !== 'undefined' || !ctx.req || !res || !email) {
      return;
    }

    if (ctx.req.cookies['neatIdeasToken']) {
      // Token should still be valid, continue.
      this.neatIdeasToken = ctx.req.cookies['neatIdeasToken'];
      return;
    }

    const neatIdeasStore = PublicEnvironment.get('NEAT_IDEAS_STORE');
    const userQueryString = `email=${encodeURIComponent(email)}&firstname=${encodeURIComponent(
      firstName || '',
    )}&lastname=${encodeURIComponent(lastName || '')}`;
    const tokenResponse = await fetch(
      `https://${neatIdeasStore}/mymotorewards/handoversso/index/gettoken?ssoid=mymotorewards&${userQueryString}`,
      { redirect: 'manual' },
    );

    if (!tokenResponse) {
      // TODO: error (MRSD-68)
      return;
    }
    const locationURL = tokenResponse.headers.get('location');
    if (locationURL === null) {
      // TODO: error (MRSD-68)
      return;
    }
    const url = new URL(locationURL!);
    const tokenPath = url.pathname;
    const token = tokenPath.substring(tokenPath.lastIndexOf('/') + 1);
    this.neatIdeasToken = token;
    res.setHeader('Set-Cookie', `neatIdeasToken=${token}; Max-Age=3600; Path=/; HttpOnly; SameSite=Strict;`);
  }
}

export default UserStore;
