import React from 'react';
import { FieldInputProps, Form, Formik, FormikHelpers } from 'formik';

import Field from '@/components/forms/Field';

import SearchIcon from '@/images/icons/search.svg';

import styles from './styles.module.scss';

interface Props {
  defaultValue?: string;
  placeholder?: string;
  onChange?: (searchQuery: string) => void;
  onSubmit?: (values: SearchProps, formikHelpers?: FormikHelpers<SearchProps>) => void | Promise<any>;
}

const SearchBox = ({ onChange, onSubmit, defaultValue, placeholder }: Props) => {
  const initialProps: SearchProps = { search: defaultValue ?? '' };

  const formikSubmit = async (values: SearchProps) => {
    await onSubmit?.(values);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldProps: FieldInputProps<SearchProps>,
    values: SearchProps,
  ) => {
    fieldProps.onChange(e);
    onChange?.(values?.search);
  };

  return (
    <Formik onSubmit={formikSubmit} initialValues={initialProps}>
      {({ errors, values, getFieldProps }) => (
        <Form className={styles.searchBox} data-testid="search-form">
          <div className={styles.icon}>
            <SearchIcon />
          </div>

          <Field
            name="search"
            onChange={(e) => handleChange(e, getFieldProps('search'), values)}
            placeholder={placeholder || 'Filter the results'}
            errors={errors}
            small={true}
            formik
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchBox;
