import React from 'react';
import { useQuery } from 'urql';

import SearchBox from '@/components/SearchBox';
import { extractItems } from '@/lib/GraphQLHelper';

import HeaderLinkMenu from '../HeaderLinkMenu';
import query from '../MenuBar/query.graphql';

import styles from './styles.module.scss';

const BottomBar = () => {
  const [{ data }] = useQuery<HeaderLinksQuery, HeaderLinksQueryVariables>({ query });
  const headerLinks = extractItems<HeaderLinkFragment>(data?.headerLinks);

  const goToSearch = (values: SearchProps) => {
    window.location.assign(`/search?q=${values?.search}`);
  };

  return (
    <div className={styles.bottomBar}>
      <SearchBox placeholder="Search" onSubmit={goToSearch} />
      {headerLinks?.map((headerLink) => (
        <HeaderLinkMenu key={headerLink.slug} headerLink={headerLink} />
      ))}
    </div>
  );
};

export default BottomBar;
