import React from 'react';
import cc from 'classcat';
import { DeepMap, FieldError } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { Field as FormikField } from 'formik';

import styles from './styles.module.scss';

export type Props = {
  name: string;
  inputRef?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement>;
  label?: string;
  small?: boolean;
  errors?: DeepMap<Record<string, any>, FieldError>;
  color?: 'red' | 'green' | 'orange' | 'blue';
  formik?: boolean;
  newStylingApplied?: boolean;
  style?: React.CSSProperties | undefined;
  runAfterRender?: () => any;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Field = ({
  label,
  errors,
  inputRef,
  small,
  color,
  style,
  formik,
  newStylingApplied,
  runAfterRender,
  ...props
}: Props) => {
  useEffectOnce(() => {
    if (formik) {
      runAfterRender?.();
    }
  });

  return (
    <div
      className={cc({ [styles.field]: true, [styles.small]: small, [styles.newStyles]: newStylingApplied })}
      style={style}
      data-color={color}
    >
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      {formik ? (
        <FormikField {...props} data-color={color} data-testid={`${props.name}-field`} />
      ) : (
        <input ref={inputRef} {...props} data-color={color} data-testid={`${props.name}-field`} />
      )}
      {errors?.[props.name] && (
        <span data-testid="error" className={styles.error}>
          {errors[props.name].message || errors[props.name]}
        </span>
      )}
    </div>
  );
};

export default Field;
