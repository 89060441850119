import * as React from 'react';
import { configure } from 'mobx';
import { Provider, enableStaticRendering } from 'mobx-react';
import { setAppElement } from 'react-modal';
import { AppProps } from 'next/app';
import Head from 'next/head';

import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import useSetUserSession from '@/hooks/useSetUserSession';
import { useStores } from '@/hooks/useStores';
import createFavicon from '@/lib/Favicon';
import getCustomHeadContent from '@/lib/ThemeSetup';
import '@/styles/main.scss';

enableStaticRendering(typeof window === 'undefined');
configure({ enforceActions: 'observed' });
setAppElement('#__next');

export default function Main({ Component, pageProps }: AppProps) {
  const stores = useStores(pageProps.mobxState);
  const favicon = stores?.globals?.favicon;
  const site = stores?.globals?.site;
  const CustomHeadContent = getCustomHeadContent(stores?.globals?.theme);

  useSetUserSession(stores);

  return (
    <Provider {...stores}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
        />
        {!!favicon?.length && (
          <link rel="icon" type="image/svg+xml" href={'data:image/svg+xml,' + createFavicon(favicon)} />
        )}

        {CustomHeadContent?.map((elem) => elem)}
      </Head>
      <Header {...pageProps} />
      <main data-platform={!!site}>
        <Component {...pageProps} />
      </main>
      <Footer {...pageProps} />
    </Provider>
  );
}
