import React from 'react';
import ReactDOMServer from 'react-dom/server';

const createFavicon = (favicon: Favicon) => {
  const faviconText = favicon?.find((s) => s.__typename === 'favicon_text_BlockType') as FaviconText;
  const faviconBackground = favicon?.find(
    (s) => s.__typename === 'favicon_background_BlockType',
  ) as FaviconBackgroundColour;
  const faviconSVG = (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect
            fill={faviconBackground?.faviconBackgroundColour || '#ffffff'}
            x="0"
            y="0"
            width="48"
            height="48"
            rx="10"
            ry="10"
          ></rect>
          <text
            fill={faviconText?.faviconTextColour || '#427bff'}
            textAnchor="middle"
            fillRule="nonzero"
            fontFamily="Helvetica, Arial, sans-serif"
            fontSize="28"
            fontWeight="bold"
            letterSpacing="0.25"
          >
            <tspan x="24" y="34">
              {faviconText?.faviconText || ''}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
  return encodeURIComponent(ReactDOMServer.renderToStaticMarkup(faviconSVG));
};

export default createFavicon;
