import { useMemo } from 'react';

import * as getStores from '@/stores/index';

const getKeyNameStore = (fnName: string) => fnName.replace(/^get(.)/, (_, p1: string) => p1.toLowerCase());

export function initStores() {
  const stores: Record<string, any> = {};
  for (const fnName in getStores) {
    const storeKeyName = getKeyNameStore(fnName);
    stores[storeKeyName] = getStores[fnName as keyof typeof getStores]();
  }

  return { ...stores } as unknown as Stores;
}

export function useStores(initialState: any) {
  const stores = useMemo(() => {
    const stores: Record<string, any> = {};
    for (const fnName in getStores) {
      const storeKeyName = getKeyNameStore(fnName);
      const initialProps = initialState ? JSON.parse(initialState[storeKeyName]) : undefined;
      switch (storeKeyName) {
        default:
          stores[storeKeyName] = getStores[fnName as keyof typeof getStores](initialProps);
          break;
      }
    }
    return stores as unknown as Stores;
  }, [initialState]);
  return stores;
}

export function serializeStores(stores: Stores) {
  const serialized: Record<string, any> = {};
  Object.entries(stores).forEach(([k, store]) => {
    serialized[k] = JSON.stringify(store);
  });
  return serialized;
}
