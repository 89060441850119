import PublicEnvironment from '@/stores/PublicEnvironment';

interface SendRequestParams<T> {
  path: string;
  headers?: Record<string, any>;
  body?: T;
  formBody?: BodyInit;
  method?: 'POST' | 'GET';
  searchParams?: URLSearchParams;
}

export interface SendRequestResponse<T> {
  success: boolean;
  data: T | null;
}

/**
 * Simple fetch wrapper for sending request isomorphically.
 * @param params
 * @throws Error SendRequest failed
 */
export default async function SendRequest<Params, Return>({
  path,
  headers,
  body,
  formBody,
  method = 'GET',
  searchParams,
}: SendRequestParams<Params>): Promise<SendRequestResponse<Return>> {
  const [protocol, server] = getProtocol();
  const search = searchParams ? '?' + searchParams.toString() : '';

  const _headers = new Headers({
    Accept: 'application/json',
    ...headers,
  });
  if (body) _headers.set('Content-Type', 'application/json');

  const response = await fetch(`${protocol}//${server}${path}` + search, {
    method,
    body: body ? JSON.stringify(body) : formBody,
    headers: _headers,
    credentials: 'same-origin',
  });
  const json: Return = await response.json();

  return {
    success: response.ok,
    data: json,
  };
}

export function getProtocol() {
  const server = PublicEnvironment.get('MOCK_REST_SERVER')
    ? 'localhost:3001'
    : typeof window !== 'undefined'
    ? window.location.host
    : PublicEnvironment.get('NGINX_HOST');
  const protocol = (typeof window !== 'undefined' && window.location.protocol) || 'http:';
  return [protocol, server];
}
