import { useEffectOnce } from 'react-use';

import CSRF from '@/lib/Csrf';

// Always refetch session client side
const useSetUserSession = (stores: Stores) => {
  useEffectOnce(() => {
    (async () => {
      const session = await CSRF.getSessionInfo();
      if (session) {
        stores.userStore.init(session);
      }
    })();
  });
};

export default useSetUserSession;
