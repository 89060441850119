import React from 'react';
import { useQuery } from 'urql';

import { CategoriesSelect, SearchBox } from '@/components';
import useIsMobile from '@/hooks/useIsMobile';
import { extractItems } from '@/lib/GraphQLHelper';

import HeaderLinkMenu from '../HeaderLinkMenu';
import query from './query.graphql';

import styles from './styles.module.scss';

const MenuBar = () => {
  const [isLtLarge, isLtDesktop] = useIsMobile(1281, 1025);
  const [{ data }] = useQuery<HeaderLinksQuery, HeaderLinksQueryVariables>({ query });
  const headerLinks = extractItems<HeaderLinkFragment>(data?.headerLinks);

  const goToSearch = (values: SearchProps) => {
    window.location.assign(`/search?q=${values?.search}`);
  };

  return (
    <div className={styles.menuBar} data-testid="menu-bar">
      {!isLtLarge && <CategoriesSelect />}
      {!isLtDesktop && (
        <>
          <SearchBox placeholder={`Search`} onSubmit={goToSearch} />
          {headerLinks?.map((headerLink) => (
            <HeaderLinkMenu key={headerLink.slug} headerLink={headerLink} />
          ))}
        </>
      )}
    </div>
  );
};

export default MenuBar;
