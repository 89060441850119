import React, { useMemo, useRef, useState } from 'react';
import { useQuery } from 'urql';

import Button from '@/components/Button';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { extractItems } from '@/lib/GraphQLHelper';

import query from './query.graphql';

import styles from './styles.module.scss';

const CategoriesSelect = () => {
  const [{ data }] = useQuery<CategoriesQuery>({ query });
  const [openModal, setOpenModal] = useState(false);
  const [selection, setSelection] = useState<'benefits' | 'shop'>('benefits');
  const offerCategories = extractItems<CategoryFragment>(data?.offerCategories);
  const productCategories = extractItems<ProductCategoryFragment>(data?.productCategories);
  const background = useRef<HTMLDivElement>(null);

  useOnClickOutside(background, () => setOpenModal(false));

  const categorySelected = useMemo(
    () => ({
      benefits: {
        title: 'Benefits',
        slug: '/offers',
        categories: offerCategories?.map((c) => ({
          title: c?.title,
          slug: c?.slug?.toString(),
        })),
      },
      shop: {
        title: 'Online Shop',
        slug: '/shop',
        categories: productCategories?.map((c) => ({
          title: c?.title,
          slug: c?.slug?.toString(),
        })),
      },
    }),
    [offerCategories, productCategories],
  );

  return (
    <div className={styles.categoriesSelect} data-open={openModal}>
      <Button data-testid="openModal" onClick={() => setOpenModal(!openModal)} design="ui">
        Categories
      </Button>
      {openModal && (
        <>
          <div className={styles.background} data-open={openModal} ref={background} />
          <div className={styles.modal} data-open={openModal}>
            <div className={styles.left}>
              <Button
                data-testid="benefitsBtn"
                data-active={selection === 'benefits'}
                design="highlight-borders"
                onClick={() => setSelection('benefits')}
              >
                Benefits
              </Button>
              <Button
                data-testid="shopBtn"
                data-active={selection === 'shop'}
                design="highlight-borders"
                onClick={() => setSelection('shop')}
              >
                Shop
              </Button>
            </div>
            <div className={styles.right}>
              <div className={styles.top}>
                <h2>{categorySelected[selection].title}</h2>
                <a href={categorySelected[selection].slug}>Browse All</a>
              </div>
              <div className={styles.categories}>
                {categorySelected[selection].categories?.map((c) => (
                  <a href={`${categorySelected[selection].slug}/${c.slug}`} key={c?.title}>
                    {c?.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoriesSelect;
