import React from 'react';

import { hexToHSL } from './Utils';

const getCustomHeadContent = (theme: Nullable<Theme.ThemeData>) => {
  const CustomHeadContent: JSX.Element[] = [];

  if (theme?.customisation && theme?.system) {
    const font = theme.customisation.font?.replace(/\+/g, ' ');
    const styles: string[] = [];
    CustomHeadContent.push(
      <link
        key="googleFont"
        href={`https://fonts.googleapis.com/css2?family=${font}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
        rel="stylesheet"
        id="googleFont"
      />,
    );

    const accentHSL = hexToHSL(theme.customisation.accentColour!);
    // prettier-ignore
    styles.push(`
          *, button, input, optgroup, select, textarea { font-family: ${font ? `'${font}'` : "Helvetica, Arial"}, sans-serif; }
          :root {
            --main-font: ${font ? `'${font}'` : "Helvetica, Arial"}, sans-serif;
            --colour-primary-accent: ${theme.customisation.accentColour} !important;
            --colour-primary-accent-h: ${accentHSL.h} !important;
            --colour-primary-accent-s: ${accentHSL.s}% !important;
            --colour-primary-accent-l: ${accentHSL.l}% !important;
            --colour-primary-highlight: ${theme.customisation.highlightColour} !important;
            --colour-primary-middle: ${theme.customisation.middleColour} !important;
            --colour-primary-dark: ${theme.customisation.darkColour} !important;
            --colour-secondary-page-bg: ${theme.system.pageBackgroundColour} !important;
            --colour-secondary-ui-middle: ${theme.system.uiMiddleColour} !important;
            --colour-tertiary-error: ${theme.system.errorColour} !important;
            --colour-tertiary-white: ${theme.system.whiteColour} !important;
            --colour-tertiary-pricesavings: ${theme.system.priceSavingsColour} !important;
            --colour-tertiary-accent: ${theme.system.tertiaryAccentColour} !important;
          }
        `);

    if (styles.length) {
      CustomHeadContent.push(<style key="customStyles" dangerouslySetInnerHTML={{ __html: styles.join('') }} />);
    }
  }

  return CustomHeadContent;
};

export default getCustomHeadContent;
