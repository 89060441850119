/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo } from 'react';
import { useQuery } from 'urql';

import ReferModal from '@/components/ReferModal';
import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { extractItems } from '@/lib/GraphQLHelper';
import { withUrql } from '@/lib/urqlClient';

import FacebookIcon from '@/images/icons/facebook-black.svg';
import InstagramIcon from '@/images/icons/instagram.svg';
import TwitterIcon from '@/images/icons/twitter.svg';

import LinkSection from './LinkSection';
import query from './query.graphql';

import styles from './styles.module.scss';

const Footer = () => {
  const { siteName, logo } = useStore('globals');
  const [isMobile] = useIsMobile(768);
  const [{ data }] = useQuery<FooterQuery>({ query });

  const socialLinks = useMemo(() => {
    if (data?.seomatic?.metaSiteVarsContainer) {
      const { sameAsLinks } = JSON.parse(data.seomatic.metaSiteVarsContainer);
      return (
        <div className={styles.socialLinks}>
          <h3>Follow us</h3>
          {sameAsLinks.twitter.url && (
            <a href={sameAsLinks.twitter.url} target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
          )}
          {sameAsLinks.instagram.url && (
            <a href={sameAsLinks.instagram.url} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          )}
          {sameAsLinks.facebook.url && (
            <a href={sameAsLinks.facebook.url} target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          )}
        </div>
      );
    }
    return null;
  }, [data?.seomatic?.metaSiteVarsContainer]);

  return (
    <div className={styles.footerContainer}>
      <footer className={styles.footer}>
        <div className={styles.inner} data-testid="footer">
          <div className={styles.top}>
            <div className={styles.left}>
              {logo && (
                <div className={styles.logo}>
                  <img src={logo} alt={siteName} />
                </div>
              )}
              {!isMobile && socialLinks}
            </div>
            <div className={styles.right}>
              {extractItems<FooterLinkFragment>(data?.entries)?.map(
                (s) => !!s && <LinkSection section={s} key={s.slug} />,
              )}
              {isMobile && socialLinks}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <p>Copyright &copy; {new Date().getFullYear()} mymoto Rewards</p>
              <a className={styles.referralLink} href="https://mymoto.com.au/refer">
                Refer a friend
              </a>
              {!isMobile ? <>&nbsp;&nbsp;|&nbsp;&nbsp;</> : <br />}
              <a href="/terms-and-conditions">Terms &amp; Conditions</a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="/privacy">Privacy</a>
            </div>
          </div>
        </div>
      </footer>
      <ReferModal />
    </div>
  );
};

export default withUrql(Footer);
