interface PublicEnvironmentVariables {
  APP_VERSION: string;
  ENV: 'development' | 'staging' | 'production' | 'test' | 'unknown';
  MOCK_GRAPHQL_SERVER: boolean;
  MOCK_REST_SERVER: boolean;
  NGINX_HOST: string;
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
  S3_BUCKET: string;
  MAPBOX_TOKEN: string;
  GOOGLE_MAPS_KEY: string;
  STRIPE_PUB_KEY: string;
  BLACKHAWK_HOST: string;
  TOKEN: string;
  NEAT_IDEAS_STORE: 'uat-stores.neatideas.com.au' | 'stores.neatideas.com.au';
}

export default class PublicEnvironment {
  static #instance: PublicEnvironment;

  env: PublicEnvironmentVariables = {
    APP_VERSION: 'latest',
    ENV: 'unknown',
    MOCK_GRAPHQL_SERVER: false,
    MOCK_REST_SERVER: false,
    NGINX_HOST: 'localhost',
    SENTRY_DSN: '',
    SENTRY_ENABLED: false,
    S3_BUCKET: '',
    MAPBOX_TOKEN: '',
    GOOGLE_MAPS_KEY: 'changeme',
    STRIPE_PUB_KEY: '',
    BLACKHAWK_HOST: '',
    TOKEN: '',
    NEAT_IDEAS_STORE: 'stores.neatideas.com.au',
  };

  constructor(initialProps?: Partial<PublicEnvironment>) {
    if (typeof window !== 'undefined' && window.__NEXT_DATA__?.props.pageProps.mobxState.publicEnvironment) {
      // On Client side, we can use __NEXT_DATA__ immediately to initialise this singleton
      Object.assign(this, JSON.parse(window.__NEXT_DATA__.props.pageProps.mobxState.publicEnvironment));
    } else if (initialProps) {
      Object.assign(this, initialProps);
    } else {
      this.env.MOCK_GRAPHQL_SERVER = process.env.MOCK_GRAPHQL_SERVER === 'true';
      this.env.MOCK_REST_SERVER = process.env.MOCK_REST_SERVER === 'true';
      this.env.NGINX_HOST = process.env.NGINX_HOST || 'localhost';
      this.env.ENV = this.#getEnv();
      this.env.APP_VERSION = process.env.APP_VERSION || 'latest';
      this.env.SENTRY_DSN = process.env.SENTRY_DSN || '';
      this.env.SENTRY_ENABLED = process.env.SENTRY_ENABLED === 'true';
      this.env.S3_BUCKET = process.env.S3_BUCKET || '';
      this.env.MAPBOX_TOKEN = process.env.MAPBOX_TOKEN || '';
      this.env.GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY || '';
      this.env.STRIPE_PUB_KEY = process.env.STRIPE_PUB_KEY || '';
      this.env.BLACKHAWK_HOST = process.env.BLACKHAWK_HOST || '';
      this.env.TOKEN = process.env.TOKEN || '';
    }
  }

  static getInstance(initialProps?: Partial<PublicEnvironment>) {
    if (!PublicEnvironment.#instance) {
      PublicEnvironment.#instance = new PublicEnvironment(initialProps);
    }
    return PublicEnvironment.#instance;
  }

  static get<T extends keyof PublicEnvironmentVariables>(key: T): PublicEnvironmentVariables[T] {
    const instance = this.getInstance();
    return instance.env[key];
  }

  #getEnv() {
    if (process.env.NODE_ENV === 'test') {
      return 'test';
    }
    const maybeEnv = process.env.ENV;
    if (!maybeEnv || !['development', 'staging', 'production'].includes(maybeEnv)) {
      return 'unknown' as const;
    }
    return maybeEnv as PublicEnvironmentVariables['ENV'];
  }
}
