import { titleize } from 'inflection';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export const telPhone = (phone: string) => phone.replace(/[\D+]/g, '');

export const addressString = (locations?: Maybe<LocationFragment>[] | null) => {
  if (!locations) return null;
  const locationNo = locations.length;
  switch (locationNo) {
    case 0:
      return null;
    case 1:
      return locations[0]?.address?.address;
    case 2:
      return `${locations[0]?.description} & ${locations[1]?.description}`;
    default:
      return 'Multiple locations';
  }
};

export const parseLinkField = (linkField: WebLinkFragment | EntryLinkFragment) => {
  let href = '';
  let link;
  switch (linkField.__typename) {
    case 'linkField_weblink_BlockType':
      link = linkField as WebLinkFragment;
      href = link?.weblink || '';
      return href;
    case 'linkField_entryLink_BlockType':
      link = linkField as EntryLinkFragment;
      href = link?.Single?.[0]?.uri || '';
      return href;
  }
  return href;
};

export const formatVehicleRego = (dirtyRego: Maybe<string> | undefined) =>
  dirtyRego?.replace(/\W/gi, '')?.toUpperCase() || undefined;

// Only allows alphanumeric values (excluding spaces aswell) for vehicleRego
export const allowAlphaNumericKeysOnly = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (!!e.key.match(/\W/gi)?.length) {
    e.preventDefault();
  }
};

// Converts branch codes into branch names using vehicle entry and branchCodeToDealershipMap from gloabls
export const branchFormatter = (
  v: UserVehicleFragment,
  branchCodeToDealershipMap: Maybe<Pick<BranchCodeToDealershipMapTableRow, 'branchCode' | 'dealershipSlug'>>[],
) => {
  const isBranchName = v.branch?.match(/[^0-9]/g);

  // Only search for branch name in mapping if branch code
  if (!isBranchName) {
    const dealershipName = branchCodeToDealershipMap
      ?.find((mapping) => mapping?.branchCode === v?.branch)
      ?.dealershipSlug?.replace('vehicle-make', (v.make ? v?.make : '')?.toLowerCase())
      ?.replace(/-/g, ' ');

    return dealershipName ? titleize(dealershipName) : 'Not specified';
  }

  return v.branch;
};

// https://css-tricks.com/converting-color-spaces-in-javascript/
// @ts-ignore
export const hexToHSL = (H?: string) => {
  if (!H) {
    return { h: 0, s: 0, l: 0 };
  }
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    // @ts-ignore
    r = '00' + H[1] + H[1];
    // @ts-ignore
    g = '0x' + H[2] + H[2];
    // @ts-ignore
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    // @ts-ignore
    r = '0x' + H[1] + H[2];
    // @ts-ignore
    g = '0x' + H[3] + H[4];
    // @ts-ignore
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const currencyFormat = (num: number | null | undefined) =>
  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    currencyDisplay: 'symbol',
  }).format(num || 0);

// * For routing without next router
export const routeToPage = (page = '/') => typeof window !== 'undefined' && window.location.assign(page);

export const getQueryParam = (queryParam?: string | string[]) =>
  queryParam ? (Array.isArray(queryParam) ? queryParam[0] : queryParam) : undefined;
