import React, { useRef, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { Textfit } from 'react-textfit';
import { useQuery } from 'urql';

import { Button } from '@/components';
import query from '@/graphql/queries/user.graphql';
import useIsMobile from '@/hooks/useIsMobile';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useStore from '@/hooks/useStore';
import UserService from '@/lib/UserService';

import ChevronIcon from '@/images/icons/chevron.svg';
import AvatarIcon from '@/images/tabler-icons/avatar.svg';
import CarIcon from '@/images/tabler-icons/car.svg';
import CartIcon from '@/images/tabler-icons/cart.svg';
import HeartFilled from '@/images/tabler-icons/heart-filled.svg';
import HeartEmpty from '@/images/tabler-icons/heart.svg';

import styles from './styles.module.scss';

const UserMenu = () => {
  const { myCarEnabled } = useStore('globals');
  const { cartNumber } = useStore('cart');
  const { loggedIn } = useStore('userStore');
  const [{ data }] = useQuery<UserQuery, UserQueryVariables>({ query });
  const [openModal, setOpenModal] = useState(false);
  const [isMobile] = useIsMobile(768);
  const wishlistLength = data?.userDetails?.wishlist?.length;

  const background = useRef<HTMLDivElement>(null);
  useOnClickOutside(background, () => setOpenModal(false));

  const logoutUser = () => {
    setOpenModal(false);
    UserService.logoutUser();
  };

  return (
    <div className={styles.userMenu}>
      {loggedIn ? (
        <>
          <div className={styles.icons} data-testid="loggedin">
            {myCarEnabled && (
              <a href="/my-account/my-car" className={styles.icon}>
                <CarIcon />
              </a>
            )}
            {wishlistLength ? (
              <a href="/wishlist" className={styles.icon}>
                <div className={styles.count}>{wishlistLength}</div>
                <HeartFilled />
              </a>
            ) : (
              <a href="/wishlist" className={styles.icon}>
                <HeartEmpty />
              </a>
            )}
            <a href="/cart" className={cc({ [styles.icon]: true, [styles.noCircle]: !cartNumber })}>
              {!!cartNumber && <div className={styles.count}>{cartNumber}</div>}
              <CartIcon />
            </a>

            <Button onClick={() => setOpenModal(!openModal)} design="ui" data-testid="modalButton">
              <AvatarIcon />
              {!isMobile && <span>{data?.userDetails?.firstName}</span>}
              <div className={styles.chevron} data-modalopen={openModal}>
                <ChevronIcon />
              </div>
            </Button>
          </div>
          {openModal && (
            <>
              <div className={styles.background} data-modalopen={openModal} ref={background} />
              <div className={styles.userModal} data-testid="modal" data-modalopen={openModal}>
                {data?.userDetails && (
                  <>
                    <div className={styles.name}>
                      <Textfit mode="single" max={18} className={styles.fullname}>
                        {data.userDetails.firstName}
                      </Textfit>
                      <Textfit mode="single" max={18} className={styles.email}>
                        {data.userDetails.email}
                      </Textfit>
                    </div>
                    <div className={styles.horizontalLine} />
                  </>
                )}
                <div className={styles.links}>
                  <a href="/my-account/profile">Profile</a>
                  <a href="/my-account/cards">My Cards</a>
                  <a href="/my-account/billing">Billing Information</a>
                  <a href="/my-account/orders">Order History</a>
                  <a href="/my-account/claimed-offers">Claimed Offers</a>
                  {myCarEnabled && <a href="/my-account/my-car">My Vehicle</a>}
                  <a href="/my-account/interests">Interests</a>
                  <Button href="/forgot-password" design="highlight-borders">
                    Change your password
                  </Button>
                  <Button design="highlight-borders" onClick={logoutUser} data-testid="logoutButton">
                    Log out of your account
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className={styles.signin}>
          <Button href="/login" design="ui" data-testid="guest">
            Sign in
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(UserMenu);
